//store/tasks.ts

import { ActionContext } from 'vuex';
import axios from 'axios';
import { ITask } from '@/store/interfaces';

interface State {
  tasks: ITask[] | null,
}

const state: State = {
  tasks: null,
}

const getters = {
  tasks: (state: State): ITask[] | null => state.tasks,
};

const actions = {
  async getTasks({ commit }: ActionContext<State, any>) {
    try {
      const req = await axios.get(`/tasks`)
      const tasks = req.data.tasks as ITask[]

      commit('setTasks', tasks)
      return tasks
    } catch (error) {
      console.error(error)
      return []
    }
  },
  async getTask(_: ActionContext<State, any>, taskId: number | string): Promise<ITask> {
    const req = await axios.get(`/task/${taskId}`)
    const task = req.data.tasks as ITask[]

    if (task.length > 0) {
      return task[0]
    }
    throw new Error(`Could not find task with ID ${taskId}`)
  },
  async addTask({ dispatch }: ActionContext<State, any>, task: ITask) {
    try {
      const body = JSON.stringify(task);
      await axios.post(`/tasks`, body)
      await dispatch('getTasks')
      return
    } catch (error) {
      console.error(error)
      return []
    }
  },
  async updateTask({ dispatch }: ActionContext<State, any>, task: ITask) {
    try {
      const body = JSON.stringify(task);
      await axios.put(`/task/${task.id}`, body)
      await dispatch('getCards')
      return
    } catch (error) {
      console.error(error)
      return []
    }
  },
};

const mutations = {
  setTasks(state: State, tasks: ITask[]) {
    state.tasks = tasks
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
