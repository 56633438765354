//import { createStore } from 'vuex'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import user from './modules/user'
import eventcards from './modules/eventcards'
import pointbook from './modules/pointbook'
import tasks from './modules/tasks'
import pomodoro from './modules/pomodoro'

export default new Vuex.Store({
  modules: {
    user,
    eventcards,
    pointbook,
    tasks,
    pomodoro
  },
  plugins: [createPersistedState()]
})