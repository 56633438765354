<template>
  <div class="row compact-scoreboard">
    <div class="col-6 text-left">
      <p class="compact-text">Your Score: <b>{{ points?.totalPoints || 0 }}</b></p>
      <p class="compact-text">Weekly Score: <b>{{ points?.weeklyPoints || 0 }}</b> <small>(+100% last week)</small></p>
    </div>
    <div class="col-6 d-flex justify-content-end">
      <button :class="checkedIn ? 'btn btn-outline-success' : 'btn btn-danger'" class="mx-2 compact-button"
        @click="checkInAndOut()">{{ checkedIn ? 'Check out' : 'Check in' }}</button>
      <button class="btn btn-success mx-2 compact-button" @click="$emit('eventModal')">Add
        Event</button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, onMounted } from 'vue';
import { IPoints } from '@/store/interfaces';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'WelcomeBar',
  emits: ['eventModal'],
  setup() {
    const store = useStore();
    const checkedIn = ref(false);
    const points = ref<IPoints>({ totalPoints: 0, weeklyPoints: 0 });

    const checkInAndOut = async () => {
      checkedIn.value = await store.dispatch("checkIn");
    };

    const getPoints = async () => {
      points.value = await store.dispatch("getPoints");
    };

    onMounted(async () => {
      await getPoints(); // Initially fetch points
      checkedIn.value = await store.dispatch("getCheckInState");
    });

    // Watch the store's points state
    watch(() => store.getters.points, (newPoints) => {
      points.value = newPoints;
    }, { immediate: true });

    return {
      checkedIn,
      points,
      checkInAndOut
    };
  }
});
</script>

<style lang="scss" scoped>
.compact-scoreboard {
  align-items: center;
}

.compact-text {
  margin: 0;
  padding: 2px 0;
  line-height: 1.2;
  text-align: left;
}

.compact-button {
  padding: 5px 10px;
  font-size: 0.875rem;
}
</style>
