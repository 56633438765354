// store/user.ts

import axios from 'axios'
import { ActionContext } from 'vuex'

export interface IUser {
  id?: number,
  createdAt?: string,
  updatedAt?: string,
  username: string,
  email: string,
  displayName: string,
  isDeleted?: boolean,
  checkedIn?: boolean,
  token?: string,
  password?: string
}

export interface IConfigurations {
  checkInTime: string,
  checkOutTime: string,
  checkInReward: number,
  checkOutReward: number,
  tolerance: number
}

interface State {
  user: IUser | null,
  loginError: boolean | null,
  regError: boolean | null,
  configurations: IConfigurations | null,
}

const state: State = {
  user: null,
  loginError: null,
  regError: null,
  configurations: null
}

const getters = {
  isLoggedIn: (state: State): boolean => !!state.user,
  loginError: (state: State): boolean | null => state.loginError,
  registrationError: (state: State): boolean | null => state.loginError,
  user: (state: State): IUser | null => state.user,
  configurations: (state: State): IConfigurations | null => state.configurations
}

const actions = {
  async login({ commit }: ActionContext<State, State>, login: { username: string, password: string }) {
    commit('setLoginError', false)
    try {
      const body = JSON.stringify(login)
      const response = await axios.post('/auth/login', body)

      const user = response.data.auth.user as IUser
      user.token = response.data.auth.token

      axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token
      commit('setUser', user)
    } catch (error) {
      commit('setLoginError', true)
    }
  },
  async logout({ commit }: ActionContext<State, State>) {
    const user = null
    commit('logout', user)
  },
  async register({ commit }: ActionContext<State, State>, userData: IUser) {
    commit('setRegErr', false)
    try {
      const body = JSON.stringify(userData)
      const req = await axios.post('/auth/register', body)

      const user = req.data.auth.user as IUser
      user.token = req.data.auth.token

      axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token
      commit('setUser', user)

      return true
    } catch (error) {
      commit('setLoginError', true)
      return false
    }
  },
  async createConfigs({ commit }: ActionContext<State, State>, configurations: IConfigurations) {
    try {
      const body = JSON.stringify(configurations)
      const response = await axios.post('/configurations', body)

      const config = response.data.configurations as IConfigurations
      commit('setConfigurations', config)
    } catch (error) {
      console.error(error)
    }
  },
}

const mutations = {
  setUser(state: State, user: IUser | null) {
    state.user = user
  },
  setLoginError(state: State, error: boolean) {
    state.loginError = error
  },
  setRegErr(state: State, error: boolean) {
    state.regError = error
  },
  logout(state: State) {
    state.user = null
  },
  setConfigurations(state: State, configurations: IConfigurations) {
    state.configurations = configurations
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
