<template>
    <nav class="navbar navbar-expand-lg bg-body-tertiary">
        <div class="container-fluid m-2">
            <!-- Logo -->
            <a class="navbar-brand" href="#">
                <img src="@/assets/img/kunstgeier.png" alt="Be.Better" width="60" height="60">
            </a>
            <!-- Menu collapse toggle -->
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <!-- Home -->
                    <li class="nav-item">
                        <router-link :to="{ name: 'home' }" class="nav-link active" aria-current="page">Home</router-link>
                    </li>
                    <!-- User Container -->
                    <!-- <template v-if="this.loggedIn"> -->
                        <!-- Events -->
                        <!-- <li class="nav-item">
                            <router-link :to="{ name: 'home' }" class="nav-link">Events</router-link>
                        </li> -->
                        <!-- Reports -->
                        <!-- <li class="nav-item">
                            <router-link :to="{ name: 'home' }" class="nav-link">Reports</router-link>
                        </li>
                    </template> -->


                    <!-- Not Logged In -->
                    <li class="nav-item" v-if="!loggedIn">
                        <router-link :to="{ name: 'login' }" class="nav-link">Login</router-link>
                    </li>
                    <li class="nav-item" v-if="!loggedIn">
                        <router-link :to="{ name: 'register' }" class="nav-link">Register</router-link>
                    </li>

                    <!-- Logged In -->
                    <li class="nav-item" v-if="loggedIn">
                        <router-link :to=" { name: 'history' } " href="#!" class="nav-link">History</router-link>
                    </li>
                    <li class="nav-item" v-if="loggedIn">
                        <router-link :to=" { name: 'templates' } " href="#!" class="nav-link">Templates</router-link>
                    </li>
                    <li class="nav-item" v-if="loggedIn">
                        <router-link :to=" { name: 'tasks' } " href="#!" class="nav-link">Tasks</router-link>
                    </li>
                    <li class="nav-item" v-if="loggedIn">
                        <router-link :to=" { name: 'pomodoro' } " href="#!" class="nav-link">Pomodoro</router-link>
                    </li>
                    <li class="nav-item" v-if="loggedIn">
                        <router-link @click="$store.dispatch('logout')" :to=" { name: 'login' }"  href="#!" class="nav-link">Logout</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    methods: {
    
        async logout() {
            await this.$store.dispatch("logout")
            this.$router.push('/login')
        }
    },
    computed: {
        loggedIn: function () {
            return this.$store.getters.isLoggedIn;
        },
        authText: function () {
            return this.loggedIn == true ? "Logout" : "Login";
        }
    },
}
</script>

<style lang="scss" scoped>
.navbar {
    color: $text-color;
}
#title {
    margin-top: 0.5em;
    padding-left: 1em;
}

li {
    margin: 1em;
}

nav {
    // background-image: linear-gradient(90deg, $btn-green, $btn-green-hover);
    background-image: linear-gradient(90deg, $primary-color, $accent-color);
    // background-image: linear-gradient(90deg, #54e7cf, rgba(23, 209, 39, .7450980392156863));
}

.navbar {
    .nav-link {
        color: $text-color !important;

        &.router-link-exact-active {
            color: $text-highlight-color !important;
        }
  }
}

.navbar-toggler * {
    color: white !important;
}
</style>