<template>
  <div class="modal" tabindex="-1" :class="{ 'd-block': show }" style="background: rgba(0, 0, 0, 0.5);">
    <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
            <h5 class="modal-title">Share Your Cards</h5>
            <button type="button" class="btn-close" @click="$emit('close')" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p class="text-warning">This feature is not implemented yet!</p>
            <div v-if="cards.length === 0">
              <p>Loading your cards...</p>
            </div>
            <div v-else>
              <p>Select cards to share:</p>
              <ul class="list-group">
                <li v-for="card in cards" :key="card.id" class="list-group-item d-flex align-items-center">
                  <input class="form-check-input me-2" type="checkbox" :id="'card-' + card.id" v-model="selectedCards" :value="card">
                  <label class="form-check-label" :for="'card-' + card.id">{{ card.title }}</label>
                </li>
              </ul>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="$emit('close')">Close</button>
            <button type="button" class="btn btn-primary" @click="shareSelectedCards">Share</button>
          </div>
            </div>
        </div>
    </div>
</template>
  <script>
  export default {
    data() {
      return {
        cards: [],
        selectedCards: []
      };
    },
    props: {
      show: {
        type: Boolean,
        default: false
      }
    },
    async created() {
      console.log("Share Cards Modal triggering getCards()...")
      this.cards = await this.$store.dispatch("getCards");
    },
    methods: {
      shareSelectedCards() {
        // Logic to share selected cards
        console.log('Sharing these cards:', this.selectedCards);
        this.$emit('close');
      }
    }
  };
  </script>
  
  <style scoped>
  .modal-dialog {
    max-width: 500px;
  }
  </style>
  