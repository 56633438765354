<template>
  <div v-if="localNotification && isVisible" class="modal fade show" style="display: block; background: rgba(0, 0, 0, 0.5);" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ localNotification.title }}</h5>
          <button type="button" class="btn-close" @click="closeModal"></button>
        </div>
        <div class="modal-body text-left">
          <h5><b>Message:</b></h5>
          <p>{{ localNotification.message }}</p>
          <div v-if="localNotification.stack">
            <h6>Stack Trace:</h6>
            <pre class="bg-light p-2"><code>{{ localNotification.stack }}</code></pre>
          </div>
          <div v-if="localNotification.componentInfo">
            <h6>Component Info:</h6>
            <p>{{ localNotification.componentInfo }}</p>
          </div>
          <div v-if="localNotification.additionalInfo && Object.keys(localNotification.additionalInfo).length > 0">
            <h6>Additional Info:</h6>
            <pre class="bg-light p-2"><code>{{ JSON.stringify(localNotification.additionalInfo, null, 2) }}</code></pre>
          </div>
        </div>
        <div class="modal-footer">
          <button v-if="showPomodoroLink" type="button" class="btn btn-primary" @click="navigateToPomodoro">Go to Pomodoro</button>
          <button type="button" class="btn btn-danger" @click="closeModal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, defineProps, defineEmits, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const router = useRouter();
const store = useStore();

interface ErrorNotification {
  title: string;
  message: string;
  stack?: string;
  componentInfo?: string;
  additionalInfo?: Record<string, unknown>;
}

const props = defineProps<{ 
  notification: ErrorNotification | null;
}>();

const emit = defineEmits(['close']);

const isVisible = ref(false);
const showPomodoroLink = ref(false);
const localNotification = ref<ErrorNotification | null>(null);

const closeModal = () => {
  isVisible.value = false;
  showPomodoroLink.value = false;
  emit('close');
};

const navigateToPomodoro = () => {
  closeModal();
  router.push('/pomodoro');
};

// Watch for changes in the notification prop
watch(() => props.notification, (newNotification) => {
  if (newNotification) {
    localNotification.value = { ...newNotification };
    isVisible.value = true;
    showPomodoroLink.value = newNotification.title === 'Timer Completed';
  } else {
    isVisible.value = false;
    localNotification.value = null;
  }
}, { immediate: true, deep: true });

// Watch for timer completion
watch(
  () => store.state.pomodoro.time,
  (newTime) => {
    if (newTime === 0) {
      const phase = store.state.pomodoro.phase;
      isVisible.value = true;
      localNotification.value = {
        title: 'Timer Completed',
        message: `${phase.charAt(0).toUpperCase() + phase.slice(1)} phase completed!`,
      };
      showPomodoroLink.value = true;
    }
  }
);

onMounted(() => {
  if (props.notification) {
    localNotification.value = { ...props.notification };
    isVisible.value = true;
    showPomodoroLink.value = props.notification.title === 'Timer Completed';
  }
});
</script>

<style scoped>
.modal-backdrop {
  display: none;
}
.modal-body {
  text-align: left;
}
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}
.text-left {
  text-align: left;
}
</style>