import { ActionContext } from 'vuex';

interface PomodoroState {
  time: number;
  isRunning: boolean;
  phase: 'work' | 'break';
  workDuration: number;
  breakDuration: number;
  timeout: number | null;
  timestamp: number;
}

const state: PomodoroState = {
  time: 25 * 60,
  isRunning: false,
  phase: 'work',
  workDuration: 25 * 60,
  breakDuration: 5 * 60,
  timeout: null,
  timestamp: Date.now(),
};

const getters = {
  time: (state: PomodoroState): number => state.time,
  isRunning: (state: PomodoroState): boolean => state.isRunning,
  phase: (state: PomodoroState): 'work' | 'break' => state.phase,
  workDuration: (state: PomodoroState): number => state.workDuration,
  breakDuration: (state: PomodoroState): number => state.breakDuration,
  timeout: (state: PomodoroState): number | null => state.timeout,
  timestamp: (state: PomodoroState): number => state.timestamp,
};

const actions = {
  startTimer({ commit, state, dispatch }: ActionContext<PomodoroState, any>) {
    if (!state.isRunning) {
      commit('setIsRunning', true);
      dispatch('runTimer');
    }
  },
  runTimer({ commit, state, dispatch }: ActionContext<PomodoroState, any>) {
    if (state.isRunning && state.time > 0) {
      const timeout = setTimeout(() => {
        commit('setTime', state.time - 1);
        dispatch('runTimer');
      }, 1000);
      commit('setTimeout', timeout as unknown as number);
    } else if (state.time === 0) {
      dispatch('switchPhase');
    }
  },
  pauseTimer({ commit, state }: ActionContext<PomodoroState, any>) {
    commit('setIsRunning', false);
    clearTimeout(state.timeout as unknown as number);
  },
  resetTimer({ commit, state }: ActionContext<PomodoroState, any>) {
    commit('setIsRunning', false);
    clearTimeout(state.timeout as unknown as number);
    commit('setTime', state.phase === 'work' ? state.workDuration : state.breakDuration);
  },
  switchPhase({ commit, state, dispatch }: ActionContext<PomodoroState, any>) {
    commit('setIsRunning', false);
    clearTimeout(state.timeout as unknown as number);
    const newPhase = state.phase === 'work' ? 'break' : 'work';
    commit('setPhase', newPhase);
    commit('setTime', newPhase === 'work' ? state.workDuration : state.breakDuration);
    const alarmElement = document.getElementById('alarm') as HTMLMediaElement | null;
    alarmElement?.play();
    // alert(`Time for ${newPhase === 'work' ? 'work' : 'a break'}!`);
    dispatch('startTimer');
  },
  saveState({ state }: ActionContext<PomodoroState, any>) {
    const stateToSave = {
      time: state.time,
      isRunning: state.isRunning,
      phase: state.phase,
      workDuration: state.workDuration,
      breakDuration: state.breakDuration,
      timestamp: Date.now(),
    };
    localStorage.setItem('pomodoroState', JSON.stringify(stateToSave));
  },
  loadState({ commit, dispatch }: ActionContext<PomodoroState, any>) {
    const savedState = JSON.parse(localStorage.getItem('pomodoroState') || '{}');
    if (savedState && typeof savedState.time === 'number') {
      const elapsedTime = Math.floor((Date.now() - savedState.timestamp) / 1000);
      const remainingTime = savedState.time - (savedState.isRunning ? elapsedTime : 0);
      commit('setTime', remainingTime > 0 ? remainingTime : 0);
      commit('setIsRunning', savedState.isRunning);
      commit('setPhase', savedState.phase);
      commit('setTimestamp', savedState.timestamp);
      if (savedState.isRunning && remainingTime > 0) {
        dispatch('runTimer');
      }
    } else {
      commit('setTime', 25 * 60); // Default to 25 minutes if no valid saved state
    }
  },
  setWorkDuration({ commit }: ActionContext<PomodoroState, any>, duration: number) {
    commit('setWorkDuration', duration);
    commit('setTime', duration); // Set time to the new duration
  },
  setBreakDuration({ commit }: ActionContext<PomodoroState, any>, duration: number) {
    commit('setBreakDuration', duration);
  },
  manualSwitchPhase({ commit, state }: ActionContext<PomodoroState, any>) {
    commit('setIsRunning', false);
    clearTimeout(state.timeout as unknown as number);
    const newPhase = state.phase === 'work' ? 'break' : 'work';
    commit('setPhase', newPhase);
    commit('setTime', newPhase === 'work' ? state.workDuration : state.breakDuration);
  },
};

const mutations = {
  setTime(state: PomodoroState, payload: number) {
    state.time = payload;
  },
  setIsRunning(state: PomodoroState, payload: boolean) {
    state.isRunning = payload;
  },
  setPhase(state: PomodoroState, payload: 'work' | 'break') {
    state.phase = payload;
  },
  setTimeout(state: PomodoroState, payload: number | null) {
    state.timeout = payload;
  },
  setTimestamp(state: PomodoroState, payload: number) {
    state.timestamp = payload;
  },
  setWorkDuration(state: PomodoroState, duration: number) {
    state.workDuration = duration;
    if (state.phase === 'work') {
      state.time = duration; // Reset the timer to the new duration if in work phase
    }
  },
  setBreakDuration(state: PomodoroState, duration: number) {
    state.breakDuration = duration;
    if (state.phase === 'break') {
      state.time = duration; // Reset the timer to the new duration if in break phase
    }
  },
};

export default {
  namespaced: true, // Ensure the module is namespaced
  state,
  getters,
  actions,
  mutations,
};
