<template>
    <div v-if="!hideComponent" class="container d-flex flex-column align-items-center justify-content-center mt-5">
        <h2 class="title mb-4">
            Create cards from templates
        </h2>
        <div v-if="isLoading" class="text-center">
            <div class="spinner-border mb-2" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div class="row m-2" v-if="messages.length > 0">
            <div class="col-12">
                <div class="alert alert-info" role="alert" v-for="(message, index) in messages" :key="index">
                    {{ message }}
                    <div v-if="showRedirection">Click <router-link to="/">here</router-link> to see the created event cards</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 mb-3" v-for="(template, index) in templateUC" :key="index">
                <div class="card">
                    <div class="card-header bg-custom d-flex justify-content-between align-items-center">
                        <span class="invisible"><i class="fas fa-pencil-alt"></i></span>
                        <b class="card-header-title d-block text-center w-100">{{ template.category }}</b>
                        <a href="#!" class="toggle-button" @click="toggleDropdown(index)">
                            <i class="fas fa-chevron-down"></i>
                        </a>
                    </div>
                    <div class="card-body">
                        <h3 class="card-title">{{ template.category }}</h3>
                        <p class="card-text">Add events related to {{ template.category }}</p>
                        <button class="btn btn-success" @click="createFromTemplate(template.category)">
                            Add Template
                        </button>
                        <transition name="dropdown">
                            <div v-if="dropdownIndex === index" class="dropdown-section mt-3">
                                <hr />
                                <p>Template Details:</p>
                                <ul class="list-group list-group-flush">
                                    <li v-for="event in template.events" :key="event.title" class="list-group-item">
                                        <b>{{ event.title }}</b>: {{ event.description }}
                                    </li>
                                </ul>
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
        </div>
        <div class="marketplace-section text-center mt-5">
            <p>Discover more templates in our marketplace!</p>
            <button class="btn btn-primary" @click="openMarketplace">
                Visit Marketplace
            </button>
            <p class="mt-3">Share your own cards with others!</p>
            <button class="btn btn-secondary" @click="openShareModal">
                Share Cards
            </button>
        </div>
        <share-cards-modal :show="isShareModalOpen" @close="closeShareModal"></share-cards-modal>
    </div>
</template>

<script setup lang="ts">
import { IEventCard } from '@/store/interfaces';
import { ref } from 'vue';
import ShareCardsModal from '@/components/modals/ShareCardsModal.vue';
import { useStore } from 'vuex';
import router from '@/router';

// Data
const emit = defineEmits(['refresh'])
const props = defineProps(['redirect'])
const store = useStore()
const messages = ref<string[]>([])
const dropdownIndex = ref<number | null>(null)
const isShareModalOpen = ref(false)
const isLoading = ref(false)
const showRedirection = ref(true)
const hideComponent = ref(false)
const templateUC = ref<{ category: string, events: Partial<IEventCard>[] }[]>([
    // Default demo data
    {
        category: 'Default Cards by Ari',
        events: [
            {
                title: 'Exercise',
                description: 'Go do some healthy stuff dude',
                onFocus: false,
                isGoodEvent: true,
                isDeleted: false,
                context: [
                    { name: 'Heavy', points: 4, isDeleted: false },
                    { name: 'Moderate', points: 3, isDeleted: false },
                    { name: 'Easy', points: 2, isDeleted: false },
                ]
            },
            {
                title: 'Don\'t Consume',
                description: 'Don\'t consume you shouldn\'t',
                onFocus: true,
                isGoodEvent: false,
                maxEffectPerDay: 3,
                isDeleted: false,
                context: [
                    { name: 'Weed', points: -1, isDeleted: false },
                    { name: 'Some nasty stuff', points: -2, isDeleted: false },
                    { name: 'Some really bad stuff', points: -3, isDeleted: false },
                    { name: 'With friends', points: 1, isDeleted: false },
                    { name: 'Bored', points: 0, isDeleted: false },
                    { name: 'Sad', points: 0, isDeleted: false },
                    { name: 'Spent 10€', points: -1, isDeleted: false },
                    { name: 'Spent more than 10€', points: -2, isDeleted: false },
                    { name: 'Spent more than 50€', points: -3, isDeleted: false },
                ]
            },
            {
                title: 'Study a bit',
                description: 'How about you finish your degree some day?',
                onFocus: true,
                isGoodEvent: true,
                maxEffectPerDay: 12,
                isDeleted: false,
                context: [
                    { name: '5 hours', points: 5, isDeleted: false },
                    { name: '4 hours', points: 4, isDeleted: false },
                    { name: '3 hours', points: 3, isDeleted: false },
                    { name: '2 hours', points: 2, isDeleted: false },
                    { name: '1 hour', points: 1, isDeleted: false },
                    { name: 'Visit all lessons', points: 2, isDeleted: false },
                    { name: 'Skip whole lesson', points: -1, isDeleted: false },
                    { name: 'Attend test', points: 4, isDeleted: false },
                ]
            },
            {
                title: 'Cook for yourself',
                description: 'Stop giving so much of your money to Uber',
                onFocus: false,
                isGoodEvent: true,
                isDeleted: false,
                context: [
                    { name: 'Healthy', points: 2, isDeleted: false },
                    { name: 'Whatever', points: 1, isDeleted: false },
                ]
            },
            {
                title: 'Chores',
                description: 'Don\'t let your girlfriend be your mummy',
                onFocus: false,
                isGoodEvent: true,
                maxEffectPerDay: 5,
                isDeleted: false,
                context: [
                    { name: 'Dishwasher', points: 1, isDeleted: false },
                    { name: 'Dishes', points: 1, isDeleted: false },
                    { name: 'Vacuum', points: 2, isDeleted: false },
                    { name: 'Bathroom', points: 3, isDeleted: false },
                    { name: 'Clothes', points: 2, isDeleted: false },
                ]
            },
            {
                title: 'Save some money',
                description: 'Try to save some money dude',
                onFocus: false,
                isGoodEvent: true,
                isDeleted: false,
                context: [
                    { name: '500€', points: 10, isDeleted: false },
                    { name: '200€', points: 7, isDeleted: false },
                    { name: '100€', points: 5, isDeleted: false },
                    { name: '75€', points: 3, isDeleted: false },
                    { name: '50€', points: 2, isDeleted: false },
                ]
            }
        ]
    },
    // Lifestyle Category
    {
        category: 'Lifestyle',
        events: [
            {
                title: 'Exercise',
                description: 'Go do some healthy stuff dude',
                onFocus: false,
                isGoodEvent: true,
                isDeleted: false,
                context: [
                    { name: 'Heavy', points: 4, isDeleted: false },
                    { name: 'Moderate', points: 3, isDeleted: false },
                    { name: 'Easy', points: 2, isDeleted: false },
                ]
            },
            {
                title: 'Cook for yourself',
                description: 'Stop giving so much of your money to Uber',
                onFocus: false,
                isGoodEvent: true,
                isDeleted: false,
                context: [
                    { name: 'Healthy', points: 2, isDeleted: false },
                    { name: 'Whatever', points: 1, isDeleted: false },
                ]
            },
            {
                title: 'Chores',
                description: 'Don\'t let your girlfriend be your mummy',
                onFocus: false,
                isGoodEvent: true,
                maxEffectPerDay: 5,
                isDeleted: false,
                context: [
                    { name: 'Dishwasher', points: 1, isDeleted: false },
                    { name: 'Dishes', points: 1, isDeleted: false },
                    { name: 'Vacuum', points: 2, isDeleted: false },
                    { name: 'Bathroom', points: 3, isDeleted: false },
                    { name: 'Clothes', points: 2, isDeleted: false },
                ]
            },
            {
                title: 'Save some money',
                description: 'Try to save some money dude',
                onFocus: false,
                isGoodEvent: true,
                isDeleted: false,
                context: [
                    { name: '500€', points: 10, isDeleted: false },
                    { name: '200€', points: 7, isDeleted: false },
                    { name: '100€', points: 5, isDeleted: false },
                    { name: '75€', points: 3, isDeleted: false },
                    { name: '50€', points: 2, isDeleted: false },
                ]
            },
            // New Good Behavior Card
            {
                title: 'Sleep Well',
                description: 'Get enough rest for a healthy mind and body',
                onFocus: false,
                isGoodEvent: true,
                isDeleted: false,
                context: [
                    { name: '8 hours', points: 5, isDeleted: false },
                    { name: '7 hours', points: 4, isDeleted: false },
                    { name: '6 hours', points: 3, isDeleted: false },
                    { name: '5 hours', points: 2, isDeleted: false },
                    { name: 'Less than 5 hours', points: 1, isDeleted: false },
                ]
            },
            // New Bad Behavior Card
            {
                title: 'Procrastinate',
                description: 'Putting off important tasks',
                onFocus: true,
                isGoodEvent: false,
                isDeleted: false,
                context: [
                    { name: 'Watching TV', points: -2, isDeleted: false },
                    { name: 'Scrolling social media', points: -3, isDeleted: false },
                    { name: 'Playing video games', points: -4, isDeleted: false },
                    { name: 'Sleeping excessively', points: -1, isDeleted: false },
                ]
            },
        ]
    },
    // Studying Category
    {
        category: 'Studying',
        events: [
            {
                title: 'Study a bit',
                description: 'How about you finish your degree some day?',
                onFocus: true,
                isGoodEvent: true,
                maxEffectPerDay: 12,
                isDeleted: false,
                context: [
                    { name: '5 hours', points: 5, isDeleted: false },
                    { name: '4 hours', points: 4, isDeleted: false },
                    { name: '3 hours', points: 3, isDeleted: false },
                    { name: '2 hours', points: 2, isDeleted: false },
                    { name: '1 hour', points: 1, isDeleted: false },
                    { name: 'Visit all lessons', points: 2, isDeleted: false },
                    { name: 'Skip whole lesson', points: -1, isDeleted: false },
                    { name: 'Attend test', points: 4, isDeleted: false },
                ]
            },
            // New Good Behavior Card
            {
                title: 'Help Others Study',
                description: 'Support your peers in their learning',
                onFocus: false,
                isGoodEvent: true,
                isDeleted: false,
                context: [
                    { name: 'Tutoring session', points: 5, isDeleted: false },
                    { name: 'Group study', points: 4, isDeleted: false },
                    { name: 'Sharing notes', points: 3, isDeleted: false },
                    { name: 'Explaining concepts', points: 2, isDeleted: false },
                ]
            },
        ]
    },
    // Health Category
    {
        category: 'Health',
        events: [
            {
                title: 'Exercise',
                description: 'Go do some healthy stuff dude',
                onFocus: false,
                isGoodEvent: true,
                isDeleted: false,
                context: [
                    { name: 'Heavy', points: 4, isDeleted: false },
                    { name: 'Moderate', points: 3, isDeleted: false },
                    { name: 'Easy', points: 2, isDeleted: false },
                ]
            },
            // New Good Behavior Card
            {
                title: 'Healthy Eating',
                description: 'Eat well to stay well',
                onFocus: false,
                isGoodEvent: true,
                isDeleted: false,
                context: [
                    { name: 'Vegetables', points: 3, isDeleted: false },
                    { name: 'Fruits', points: 2, isDeleted: false },
                    { name: 'Balanced meal', points: 4, isDeleted: false },
                ]
            },
            // New Bad Behavior Card
            {
                title: 'Unhealthy Eating',
                description: 'Eating habits that are not good for health',
                onFocus: true,
                isGoodEvent: false,
                isDeleted: false,
                context: [
                    { name: 'Fast food', points: -3, isDeleted: false },
                    { name: 'Sugary snacks', points: -2, isDeleted: false },
                    { name: 'Overeating', points: -3, isDeleted: false },
                    { name: 'Skipping meals', points: -2, isDeleted: false },
                ]
            },
        ]
    },
    // Bad Habits Category
    {
        category: 'Bad Habits',
        events: [
            {
                title: 'Don\'t Consume',
                description: 'Don\'t consume you shouldn\'t',
                onFocus: true,
                isGoodEvent: false,
                maxEffectPerDay: 3,
                isDeleted: false,
                context: [
                    { name: 'Weed', points: -1, isDeleted: false },
                    { name: 'Some nasty stuff', points: -2, isDeleted: false },
                    { name: 'Some really bad stuff', points: -3, isDeleted: false },
                    { name: 'With friends', points: 1, isDeleted: false },
                    { name: 'Bored', points: 0, isDeleted: false },
                    { name: 'Sad', points: 0, isDeleted: false },
                    { name: 'Spent 10€', points: -1, isDeleted: false },
                    { name: 'Spent more than 10€', points: -2, isDeleted: false },
                    { name: 'Spent more than 50€', points: -3, isDeleted: false },
                ]
            },
            // New Bad Behavior Card
            {
                title: 'Smoking',
                description: 'Avoid smoking for better health',
                onFocus: true,
                isGoodEvent: false,
                isDeleted: false,
                context: [
                    { name: 'Cigarettes', points: -2, isDeleted: false },
                    { name: 'E-cigarettes', points: -1, isDeleted: false },
                    { name: 'Multiple times a day', points: -3, isDeleted: false },
                    { name: 'Occasional', points: -1, isDeleted: false },
                ]
            },
        ]
    },
    {
        category: 'ADHD Specific Issues',
        events: [
            {
                title: 'Break Tasks into Smaller Steps',
                description: 'Break larger tasks into smaller, manageable steps',
                onFocus: true,
                isGoodEvent: true,
                isDeleted: false,
                context: [
                    { name: 'Complete one small task', points: 2, isDeleted: false },
                    { name: 'Complete two small tasks', points: 4, isDeleted: false },
                    { name: 'Complete three or more small tasks', points: 6, isDeleted: false },
                ]
            },
            {
                title: 'Set Timers for Tasks',
                description: 'Use a timer to stay focused on tasks',
                onFocus: true,
                isGoodEvent: true,
                isDeleted: false,
                context: [
                    { name: 'Set timer for 25 minutes', points: 2, isDeleted: false },
                    { name: 'Complete task within timer', points: 4, isDeleted: false },
                    { name: 'Take a 5-minute break', points: 1, isDeleted: false },
                ]
            },
            {
                title: 'Organize Workspace',
                description: 'Keep your workspace clean and organized',
                onFocus: false,
                isGoodEvent: true,
                isDeleted: false,
                context: [
                    { name: 'Clean desk', points: 3, isDeleted: false },
                    { name: 'Organize files', points: 2, isDeleted: false },
                    { name: 'Declutter space', points: 2, isDeleted: false },
                ]
            },
            {
                title: 'Avoid Distractions',
                description: 'Identify and minimize distractions',
                onFocus: true,
                isGoodEvent: true,
                isDeleted: false,
                context: [
                    { name: 'Turn off phone notifications', points: 3, isDeleted: false },
                    { name: 'Use noise-cancelling headphones', points: 2, isDeleted: false },
                    { name: 'Limit social media use', points: 2, isDeleted: false },
                ]
            },
            {
                title: 'Procrastination Due to ADHD',
                description: 'Document instances of procrastination related to ADHD',
                onFocus: true,
                isGoodEvent: false,
                isDeleted: false,
                context: [
                    { name: 'Procrastinated task', points: -2, isDeleted: false },
                    { name: 'Delayed start', points: -3, isDeleted: false },
                    { name: 'Missed deadline', points: -4, isDeleted: false },
                ]
            },
            {
                title: 'Forgetfulness',
                description: 'Instances of forgetfulness due to ADHD',
                onFocus: true,
                isGoodEvent: false,
                isDeleted: false,
                context: [
                    { name: 'Forgot a task', points: -2, isDeleted: false },
                    { name: 'Missed an appointment', points: -3, isDeleted: false },
                    { name: 'Lost an item', points: -2, isDeleted: false },
                ]
            },
        ]
    },
])

// Methods

const createFromTemplate = async (category: string) => {
    isLoading.value = true
    const template = templateUC.value.find(t => t.category === category);

    if (template) {
        for (const card of template.events) {
            await store.dispatch("addCard", card);
        }
        console.log("Template Creator triggering getCards()...")
        await store.dispatch("getCards");
        emit('refresh');
        messages.value.push(`Added template for ${category}`);
    }
    isLoading.value = false
    if (props.redirect) {
        setTimeout(() => {
            hideComponent.value = true
        },  1000)
    }
}

const toggleDropdown = (index: number) => {
    dropdownIndex.value = dropdownIndex.value === index ? null : index;
}

const openMarketplace = () => {
    // Logic to open the marketplace
}

const openShareModal = () => {
    isShareModalOpen.value = true;
}

const closeShareModal = () => {
    isShareModalOpen.value = false;
}
</script>

<style lang="scss" scoped>
.text-danger {
    color: red;
}

.event-card {
    margin: 0.5em;
    padding: 0.5em;
    min-width: 10em;
}

.card {
    min-height: 10em;
}

.card-text {
    min-height: 1em;
    margin-bottom: 1em;
}

.card-img-top {
    padding: 0.5em;
}

.active * {
    font-weight: bold;
}

i {
    color: #ff8c8c !important;
}

.bg-custom {
    background-color: $card-header-color;
}

.bg-custom-focus {
    background-color: $accent-color;

    .card-header-title {
        color: black !important;
    }
}

.card-header {
    position: relative;
    padding-right: 50px;
}

.toggle-button {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    border-left: 0.01em dashed black;
    color: #fff;
    text-decoration: none;
    background-color: inherit;
    transition: opacity 0.2s ease, border-left-color 0.2s ease;
}

.toggle-button i {
    font-size: 1.2em;
}

.toggle-button:hover {
    opacity: 0.7;
}

.dropdown-section {
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
}

.dropdown-enter-active,
.dropdown-leave-active {
    transition: max-height 0.5s ease-in-out;
}

.dropdown-enter-from,
.dropdown-leave-to {
    max-height: 0;
}

.dropdown-enter-to,
.dropdown-leave-from {
    max-height: 500px;
    /* Adjust this value based on the content */
}
</style>
