<template>
  <div class="event-card" @mousedown.stop :class="{ 'dropdown-open': showContextDropdown }">
    <div class="card h-100">
      <!-- Card Header -->
      <div :class="['card-header', !isGoodEvent() ? 'bg-marked' : 'bg-header', 'd-flex', 'justify-content-between', 'align-items-center']">
        <div class="draggable-handle w-100 d-flex justify-content-between align-items-center"
             @touchstart="startHoldTimer" @touchend="cancelHoldTimer" @touchmove="cancelHoldTimer"
             @mousedown="startHoldTimer" @mouseup="cancelHoldTimer" @mouseleave="cancelHoldTimer"
             :class="{ 'hold-active': isHolding }">
          <span class="drag-indicator"><i class="fas fa-grip-lines"></i></span>
          <a href="#!" class="toggle-button" @click.stop="toggleDropdown">
            <i class="fas fa-chevron-down"></i>
          </a>
        </div>
      </div>
      <!-- Card Body -->
      <div class="card-body">
        <b>
          <h3 class="card-title">{{ card.title || "Loading..." }}</h3>
        </b>
        <div v-if="!card.title" class="spinner-container">
          <Spinner />
        </div>
        <p class="card-text">{{ card.description }}</p>
        <!-- Context -->
        <div class="btn-group mb-2" ref="contextDropdownContainer">
          <button class="btn btn-outline-secondary dropdown-toggle" type="button" @click.stop="toggleContextDropdown">
            Select context
          </button>
          <ul class="dropdown-menu context-dropdown" :class="{ 'show': showContextDropdown }">
            <li v-for="(context, index) in card.context" :key="index">
              <div v-if="!context.isDeleted">
                <a class="dropdown-item" :class="{ 'active': isSelected(context) }" @click.stop="addContext(context)"
                  href="#!">{{ `${context.name} (${context.points} P)` }}</a>
              </div>
            </li>
          </ul>
        </div>
        <!-- Dropdown Section -->
        <transition name="dropdown">
          <div v-show="showDropdown" class="dropdown-section">
            <hr />
            <small class="card-title">Current weekly score: <b>0</b> (+0% last week)</small>
            <br>
            <a href="#!" class="btn btn-outline-secondary edit-button m-2" @click="$emit('editCard', card)">
              <i class="fas fa-pencil-alt p-1"></i> Edit
            </a>
            <a href="#!" class="btn btn-outline-danger delete-button m-2" @click="openDeleteModal">
              <i class="fas fa-trash-alt"></i> Delete
            </a>
          </div>
        </transition>
        <!-- Display selected contexts for debugging or other purposes -->
        <div>
          <ul class="list-group list-group-flush">
            <li v-for="context in selectedContexts" :key="context.name" class="list-group-item">
              {{ `${context.name} (${context.points} P)` }}
            </li>
            <li v-if="getPoints() !== 0" class="list-group-item"><b>{{ getPoints() }} Points</b></li>
          </ul>
        </div>
        <hr />
        <p class="text-primary" v-if="showMsg">{{ msg }}</p>
        <a href="#!" :class="getButtonClass()" data-mdb-ripple-color="dark" @click="bookPoints(getPoints())">
          {{ actionMode !== 'book' ? 'Select Event' : 'Log Event' }}
        </a>
      </div>
    </div>
    <delete-confirmation-modal :show="isDeleteModalOpen" @close="closeDeleteModal"
      @confirm="confirmDelete"></delete-confirmation-modal>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted, toRefs, computed, ComputedRef } from 'vue';
import Spinner from '@/components/base/SpinnerComponent.vue';
import DeleteConfirmationModal from '@/components/modals/DeleteConfirmationModal.vue';
import { IEventCard, IContext, IPointBookEntry } from '@/store/interfaces';
import { useStore } from 'vuex';

const props = defineProps<{ cardToRender?: IEventCard, cardId?: number | string, actionMode?: 'selectOnly' | 'book' }>()
const { cardToRender, cardId } = toRefs(props)
const emit = defineEmits(['editCard', 'bookedPoints', 'logCard', 'refresh']);
const store = useStore();

const card: ComputedRef<IEventCard> = computed(() => {
  if (cardToRender.value) return cardToRender.value
  if (cardId.value) return store.getters.getCardById(cardId.value)
  throw new Error("No valid event card ID passed to component")
})

console.info("EC AM: ", props.actionMode)

const selectedContexts = ref<IContext[]>([]);
const showMsg = ref(false);
const msg = ref('');
const showDropdown = ref(false);
const isDeleteModalOpen = ref(false);

const showContextDropdown = ref(false);
const contextDropdownContainer = ref<HTMLElement | null>(null);

// Delay drag and drop operations on mobile devices to prevent accidental drags
const isHolding = ref(false);
let holdTimer: number | null = null;

const startHoldTimer = () => {
  holdTimer = setTimeout(() => {
    isHolding.value = true;
  }, 500);
};

const cancelHoldTimer = () => {
  if (holdTimer) {
    clearTimeout(holdTimer);
  }
  isHolding.value = false;
};

const isGoodEvent = () => {
  const event = card.value
  const points = event.context
    .filter(context => !context.isDeleted)
    .reduce((acc, context) => acc + Math.sign(context.points), 0)

  return (points > 0)
}

onMounted(async () => {
  // card.value = store.getters.cards.find((card: IEventCard) => card.id === props.cardId)
  // if (!props.cardToRender) {
  //   if (!props.cardId) throw new Error("No data provided to EventCardComponent!")
  //   card.value = store.getters.getCardById(props.cardId)
  // } else {
  //   card.value = props.cardToRender
  // }
  // actionMode = props.actionMode ?? 'book'
  // if (!card.value) throw new Error("No valid event card ID passed to component")
  if (!props.cardId && !props.cardToRender) throw new Error("No valid event card ID passed to component")
  document.addEventListener('click', closeContextDropdownOnOutsideClick);
})

onUnmounted(() => {
  document.removeEventListener('click', closeContextDropdownOnOutsideClick);
})

const toggleContextDropdown = (event: Event) => {
  event.stopPropagation();
  showContextDropdown.value = !showContextDropdown.value;
}

const closeContextDropdownOnOutsideClick = (event: Event) => {
  if (contextDropdownContainer.value && !contextDropdownContainer.value.contains(event.target as Node)) {
    showContextDropdown.value = false;
  }
}

const bookPoints = async (points: number) => {
  if (selectedContexts.value.length > 0) {
    let contexts: number[] = [];

    for (const context of selectedContexts.value) {
      if (!context.id) {
        console.log("Context Id missing: ", context);
        continue;
      }
      contexts.push(context.id);
    }

    const bookEntry: IPointBookEntry = {
      cardId: card.value.id,
      contexts
    };

    if (props.actionMode !== 'selectOnly') {
      await store.dispatch("bookPoints", bookEntry);

      selectedContexts.value = [];
      msg.value = `Booked ${points} Points!`;
      showMsg.value = true;
      emit('bookedPoints', card.value);
      setTimeout(() => {
        showMsg.value = false;
      }, 1500);
    }

    console.log("Emitting event: logCard", bookEntry)

    emit('logCard', bookEntry)
  }
};

const toggleDropdown = () => {
  showDropdown.value = !showDropdown.value;
};

const addContext = (context: IContext) => {
  if (!selectedContexts.value.includes(context)) {
    selectedContexts.value.push(context);
  } else {
    removeContext(context);
  }
};

const removeContext = (context: IContext) => {
  const index = selectedContexts.value.indexOf(context);
  if (index !== -1) {
    selectedContexts.value.splice(index, 1);
  }
};

const isSelected = (context: IContext): boolean => {
  return selectedContexts.value.includes(context);
};

const getPoints = (): number => {
  let points = 0;
  selectedContexts.value.forEach(context => {
    points += context.points;
  });

  const calcPoints = points < 0 ? points * -1 : points;

  if (card.value.maxEffectPerEvent && calcPoints > card.value.maxEffectPerEvent) {
    points = points > 0 ? card.value.maxEffectPerEvent : card.value.maxEffectPerEvent * -1;
  }

  if (card.value.maxEffectPerDay && calcPoints > card.value.maxEffectPerDay) {
    points = points > 0 ? card.value.maxEffectPerDay : card.value.maxEffectPerDay * -1;
  }

  return points;
};

const getButtonClass = (): string => {
  return card.value.isGoodEvent ? 'btn btn-outline-success' : 'btn btn-outline-danger';
};

const openDeleteModal = () => {
  isDeleteModalOpen.value = true;
};

const closeDeleteModal = () => {
  isDeleteModalOpen.value = false;
};

const confirmDelete = async () => {
  await store.dispatch("deleteCard", card.value.id);
  msg.value = "Card deleted successfully!";
  showMsg.value = true;
  setTimeout(() => {
    showMsg.value = false;
    emit('refresh');
  }, 3000);
  isDeleteModalOpen.value = false;
};
</script>

<style lang="scss" scoped>

.card-header {
  transition: background-color 0.2s ease-in-out;

 &:hover {
    &.bg-header {
      background-color: darken($card-header-color, 5%);
      // background-color: darken($card-header-color, 7%);
    }
    &.bg-marked {
      background-color: darken($accent-color, 5%);
      // background-color: darken($card-header-color, 15%);
    }
  }
}

.draggable-handle {
  cursor: grab;
  user-select: none;
  transition: background-color 0.3s ease;

  &:active {
    cursor: grabbing;
  }

  &.hold-active {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.drag-indicator {
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

.hold-active .drag-indicator {
  opacity: 1;
}

.event-card {
  margin: 0.5em;
  padding: 0.5em;
  min-width: 10em;
  transition: all 0.2s ease-in-out;
  position: relative;
}

.event-card.dropdown-open {
  z-index: 1000;
}

.card {
  min-height: 10em;
  transition: all 0.2s ease-in-out;
  will-change: transform;
}

.card-text {
  min-height: 1em;
  margin-bottom: 1em;
}

.card-img-top {
  padding: 0.5em;
}

.active * {
  font-weight: bold;
}

i {
  color: #ff8c8c !important;
}

.bg-header {
  background-color: $card-header-color;
  // background-color: darken($card-header-color, 4%);
}

.bg-marked {
  background-color: $accent-color;
  // background-color: darken($card-header-color, 10%);


  .card-header-title {
    color: black !important;
  }
}

.card-header {
  position: relative;
  padding-right: 50px;
  cursor: grab;
  user-select: none;
}

.card-header:active {
  cursor: grabbing;
}

.toggle-button {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  border-left: 0.01em dashed black;
  color: #fff;
  text-decoration: none;
  background-color: inherit;
  transition: opacity 0.2s ease, border-left-color 0.2s ease;
}

.toggle-button i {
  font-size: 1.2em;
}

.toggle-button:hover {
  opacity: 0.7;
}

.dropdown-section {
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

.dropdown-enter-active,
.dropdown-leave-active {
  transition: max-height 0.5s ease-in-out;
}

.dropdown-enter-from,
.dropdown-leave-to {
  max-height: 0;
}

.dropdown-enter-to,
.dropdown-leave-from {
  max-height: 500px;
}

.toggle-button {
  cursor: pointer;
  padding: 0.5rem;
}

.btn-group {
  position: relative;
}

.context-dropdown {
  position: absolute;
  z-index: 1050;
  display: none;
  left: 0;
  top: 100%;
  min-width: 100%;
}

.context-dropdown.show {
  display: block;
  z-index: 1100;
}
</style>