import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import EventView from '../views/EventView.vue'

function updateMetaTags(meta: any) {
  const metaTags = [
    { name: 'description', content: meta.description || 'Default description' },
    { property: 'og:title', content: meta.title || 'Default title' },
    { property: 'og:description', content: meta.description || 'Default description' },
    { property: 'og:image', content: meta.image || 'default-image.jpg' },
    // Add other meta tags as needed
  ];

  metaTags.forEach(tag => {
    let element = document.querySelector(`meta[name="${tag.name}"]`) || document.querySelector(`meta[property="${tag.property}"]`);
    if (element) {
      element.setAttribute('content', tag.content);
    } else {
      element = document.createElement('meta');
      if (tag.name) {
        element.setAttribute('name', tag.name);
      }
      if (tag.property) {
        element.setAttribute('property', tag.property);
      }
      element.setAttribute('content', tag.content);
      document.head.appendChild(element);
    }
  });
}


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: 'Be Better',
      description: 'Welcome to Be Better',
      // image: 'home-image.jpg'
    },
    component: EventView
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: 'Login - Be Better',
      description: 'Login to Be Better',
      // image: 'login-image.jpg'
    },
    component: () => import('@/views/LoginView.vue')
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      title: 'Register - Be Better',
      description: 'Register for Be Better',
      // image: 'register-image.jpg'
    },
    component: () => import('@/views/RegisterView.vue')
  },
  {
    path: '/history',
    name: 'history',
    meta: {
      title: 'Point Book - Be Better',
      description: 'Your Point Book History',
      // image: 'history-image.jpg'
    },
    component: () => import('@/views/HistoryView.vue')
  },
  {
    path: '/templates',
    name: 'templates',
    meta: {
      title: 'Templates - Be Better',
      description: 'Create events from templates',
      // image: 'history-image.jpg'
    },
    component: () => import('@/views/TemplatesView.vue')
  },
  {
    path: '/pomodoro',
    name: 'pomodoro',
    meta: {
      title: 'Pomodoro - Be Better',
      description: 'Increase your productivity',
      // image: 'history-image.jpg'
    },
    component: () => import('@/views/PomodoroView.vue')
  },
  {
    path: '/tasks',
    name: 'tasks',
    meta: {
      title: 'Tasks - Be Better',
      description: 'Track your goals',
      // image: 'history-image.jpg'
    },
    component: () => import('@/views/TaskView.vue')
  },
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title as string
  }

  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta)
  if (nearestWithMeta) {
    updateMetaTags(nearestWithMeta.meta)
  }
  
  next()
})

export default router
