import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap/dist/css/bootstrap.css'
import './assets/styles/style.scss'

import axios from 'axios';
axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? 'https://bebetter.daroo.org/api/' : 'http://172.25.8.145/api/';
// axios.defaults.baseURL = 'https://bebetter.daroo.org/api/'
axios.defaults.headers["content-type"] = "application/json"

createApp(App).use(store).use(router).mount('#app')

import 'bootstrap/dist/js/bootstrap.js'


// axios.defaults.headers.common['Authorization'] = user.token