//store/eventcards.ts

import { Action, ActionContext } from 'vuex';
import axios from 'axios';
import { IEventCard } from '@/store/interfaces';
import { debounce } from 'lodash';
import { reactive } from 'vue';

interface State {
  cards: IEventCard[] | null,
}

const state: State = reactive({
  cards: null,
})

const sort = (events: IEventCard[]): IEventCard[] => {
  const output: IEventCard[] = [];
  const unsortedEvents: IEventCard[] = [];

  events.forEach(event => {
    if (event.isDeleted !== false) return;
    if (event.position === 0) {
      unsortedEvents.push(event);
    } else {
      output.push(event);
    }
  });

  // Sort the output array based on the position
  output.sort((a, b) => a.position - b.position);

  // Concatenate the unsorted events at the end
  return output.concat(unsortedEvents);
};

const getters = {
  cards: (state: State): IEventCard[] | null => {
    return state.cards ? state.cards.filter(card => !card.isDeleted) : null;
  },
  getCardById: (state: State) => (id: number | string): IEventCard | undefined => {
    return state.cards?.find(card => card.id === id);
  }
};

const actions = {
  async getCards({ commit }: ActionContext<State, any>) {
    try {
      console.log("Fetching cards from store...")
      const response = await axios.get('/eventCards')
      let cards = response.data.eventCards as IEventCard[]
      cards = sort(cards)
      commit('setCards', cards)
      return cards
    } catch (error) {
      console.error(error)
      return []
    }
  },
  async getCard(_: ActionContext<State, any>, id: number | string) {
    try {
      const response = await axios.get(`/eventCard/${id}`)
      return response.data.eventCards[0] as IEventCard
    } catch (error) {
      console.error(error)
      return null
    }
  },
  async updateCard({ commit }: ActionContext<State, any>, card: IEventCard) {
    // Save for rollback
    const rollback = state.cards
    try {
      // Optimistic update
      commit("updateCard", card)

      // Push to backend
      await axios.put(`/eventCard/${card.id}`, card)
    } catch (error) {
      console.error(error)
      commit("setCards", rollback)
    }
  },
  async deleteCard({ commit }: ActionContext<State, any>, cardId: number) {
    try {
      // TO-DO: Implement optimistic update
      await axios.delete(`/eventCard/${cardId}`)
      commit('removeCard', cardId)
    } catch (error) {
      console.error(error)
    }
  },

  async addCard({ commit }: ActionContext<State, any>, card: IEventCard) {
    try {
      // TO-DO: Implement optimistic update
      const response = await axios.post(`/eventCard`, card)
      const newCard = response.data
      commit('addCard', newCard)
      return newCard
    } catch (error) {
      console.error(error)
      return null
    }
  },

  async updatePositions({ state, commit }: ActionContext<State, any>) {
    if (!state.cards) return;

    // Create updates based on the current order of cards in the state
    const updates = state.cards.map((card, index) => ({
      id: card.id,
      position: index + 1
    }));

    try {
      const response = await axios.post('/eventCards/updatePositions', { updates });
    } catch (error) {
      console.error('Error updating positions:', error);
      commit('setCards', state.cards);
    }
  },
};

const mutations = {
  setCards(state: State, cards: IEventCard[]) {
    state.cards = cards;
  },
  updateCard(state: State, card: IEventCard) {
    if (!state.cards) return
    const index = state.cards?.findIndex(c => c.id === card.id)
    if (index !== -1) {
      state.cards[index] = card
      console.log("Updated card at index", index, "with ID", card.id)
    }
  },
  removeCard(state: State, cardId: number) {
    if (!state.cards) return
    state.cards = state.cards.filter(card => card.id !== cardId)
  },
  addCard(state: State, card: IEventCard) {
    if (!state.cards) {
      state.cards = [card]
    } else {
      state.cards.push(card)
    }
  },
};

// Debounced server update function
// const debouncedUpdateCard = debounce(async (card: Partial<IEventCard>, callback: (value: void | PromiseLike<void>) => void) => {
//   try {
//     await axios.put(`/eventCard/${card.id}`, card)
//     callback()
//   } catch (error) {
//     console.error(error)
//     callback()
//     // You might want to implement a rollback mechanism here
//   }
// }, 500)
// const updateCardOnServer = async (card: Partial<IEventCard>) => {
//   try {
//     await axios.put(`/eventCard/${card.id}`, card)
//   } catch (error) {
//     console.error(error)
//     // You might want to implement a rollback mechanism here
//   }
// }
// 
// If you still want to use debounce for other purposes, you can keep it separate
// const debouncedUpdateCard = debounce(updateCardOnServer, 500)

export default {
  state,
  getters,
  actions,
  mutations
};