<template>
  <NotificationModal :notification="errorNotification" @close="closeErrorModal" />
  <!-- Header -->
  <NavigationBar />
  <!-- Container -->
  <div class="container justify-content-center align-items-center mx-auto vh-100">
    <router-view />
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, provide, getCurrentInstance, onErrorCaptured } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import NavigationBar from '@/components/layout/NavigationBarComponent.vue';
import NotificationModal from '@/components/layout/NotificationModal.vue';
import axios from 'axios';
import { App } from 'vue';

interface ErrorNotification {
  title: string;
  message: string;
  stack?: string;
  componentInfo?: string;
  additionalInfo?: Record<string, unknown>;
}

const errorNotification = ref<ErrorNotification | null>(null);
const store = useStore();
const router = useRouter();
const app = getCurrentInstance()?.appContext.app;

const handleError = (error: unknown, instance?: unknown, info?: string) => {
  const notification: ErrorNotification = {
    title: 'Error',
    message: error instanceof Error ? error.message : String(error),
    stack: error instanceof Error ? error.stack : undefined,
    componentInfo: info,
    additionalInfo: {}
  };

  if (instance && typeof instance === 'object' && 'name' in instance) {
    notification.additionalInfo!.componentName = instance.name;
  }

  if (error instanceof Error && 'config' in error && typeof error.config === 'object') {
    notification.additionalInfo!.axiosConfig = error.config;
  }

  errorNotification.value = notification;
};

const closeErrorModal = () => {
  errorNotification.value = null;
};

// Provide error handler to child components
provide('handleError', handleError);

// Global error handler
onMounted(() => {
  if (app) {
    app.config.errorHandler = (err, instance, info) => {
      handleError(err, instance, info);
      console.error('Global error:', err);
    };
  }
});

// Error boundary for the entire app
onErrorCaptured((err, instance, info) => {
  handleError(err, instance, info);
  return false; // Prevent error from propagating further
});

const checkTokenValidity = async (): Promise<void> => {
  if (store.getters.isLoggedIn) {
    console.log("logged in");
    const user = store.getters.user;
    if (user && user.token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
      console.log("current token", user.token);
      try {
        await axios.get('/auth/validate.json');
      } catch (error) {
        router.push('/login');
        console.log("Token is invalid");
        store.dispatch('logout');
      }
    }
  } else {
    console.log("unauthorized");
  }
};

onMounted(() => {
  checkTokenValidity();
});
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.container {
  padding-top: 2vh;
}
</style>