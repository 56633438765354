<template>
  <div class="home">
    <div class="row" v-if="showModal">
      <GenericEventCardModal :cardId="focusedCardId" @close="closeModal" />
    </div>
    <WelcomeBar @eventModal="showEventModal()" />
    <div class="row justify-content-center">
      <div v-if="showTemplateCreator">
        <TemplateCreator @refresh="getCards" redirect="true" />
      </div>
      <div v-else-if="!init">
        <div class="spinner-border mb-2" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <VueDraggableNext v-model="cards" @end="onDragEnd" class="row justify-content-center draggable-container"
        tag="div" :animation="150" :ghost-class="'draggable-ghost'" :chosen-class="'draggable-chosen'"
        handle=".draggable-handle" :forceFallback="true" :preventOnFilter="false">
        <transition-group type="transition" name="drag-list">
          <div class="justify-content-center col-sm-12 col-md-6 col-lg-4 col-container event-card-wrapper"
          v-for="(card) in cards" :key="card.id">
            <!-- v-for="(card) in cards" :key="`${card.id}-${card.updatedAt}`"> -->
            <EventCard v-if="card" v-bind:cardId="card.id" @refresh="getCards" @editCard="showEditModal" />
          </div>
        </transition-group>
      </VueDraggableNext>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { IEventCard } from '@/store/interfaces'
import GenericEventCardModal from '@/components/modals/GenericEventCardModal.vue'
import EventCard from '@/components/data-display/EventCardComponent.vue'
import WelcomeBar from '@/components/layout/WelcomeBarComponent.vue'
import TemplateCreator from '@/components/data-display/TemplateCreatorComponent.vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { VueDraggableNext } from 'vue-draggable-next'

// Store and router
const store = useStore()
const router = useRouter()

// Reactive data
const showModal = ref<boolean>(false)
const focusedCardId = ref<null | (string | number)>(null)
const cards = ref<IEventCard[]>([])
const showTemplateCreator = ref<boolean>(false)
const init = ref<boolean>(false)

// Methods
const showEventModal = (res?: IEventCard) => {
  if (res) {
    focusedCardId.value = res.id
  } else {
    focusedCardId.value = null // Clear data from last edit
  }
  showModal.value = true
}

const showEditModal = (res: IEventCard) => {
  showEventModal(res)
}

const closeModal = () => {
  showModal.value = false
}

const getCards = async () => {
  let req = await store.dispatch('getCards')
  if (!req) return
  cards.value = req
  if (cards.value.length > 0) init.value = true
}

const onDragEnd = async (event: any) => {
  for (let i = 0; i < cards.value.length; i++) {
    cards.value[i].position = i + 1
  }
  store.commit('setCards', [...cards.value]);
  await store.dispatch('updatePositions');
}

// Lifecycle hooks
onMounted(async () => {
  if (store.getters.isLoggedIn !== true) {
    router.push('/login')
    store.dispatch('logout')
  } else {
    await getCards()

    if (!(cards.value.length > 0)) {
      setTimeout(() => {
        showTemplateCreator.value = !cards.value.length && store.getters.isLoggedIn !== false
        init.value = true
      }, 1000)
    }
  }
})
</script>

<style scoped>
.vuedraggable {
  transition: all 0.2s ease-in-out;
}

.drag-list-move {
  transition: transform 0.2s;
}

.draggable-container {
  z-index: 1;
}

.draggable-ghost .context-dropdown {
  display: none !important;
}

.draggable-ghost {
  z-index: 1100 !important;
}

.draggable-ghost .context-dropdown {
  display: none !important;
}

.draggable-ghost {
  z-index: 1000 !important;
  opacity: 0.8;
  background: #f0f0f0;
  border: 1px dashed #aaa;
}

.draggable-chosen {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  transform: scale(1.02);
  z-index: 1050;
}

.event-card-wrapper {
  position: relative;
}

.event-card-wrapper:hover {
  z-index: 1100;
}

.event-card-wrapper {
  position: relative;
}

.event-card-wrapper:hover {
  z-index: 1000;
}
</style>
