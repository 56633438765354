<template>
  <!-- Modal -->
  <div ref="launchButton" class="" data-bs-toggle="modal" data-bs-target="#cardModal"></div>
  <div class="modal fade" id="cardModal" tabindex="-1" aria-labelledby="cardModalLabel" aria-hidden="false">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="cardModalLabel">{{ mode === 'edit' ? 'Edit' : 'Add' }} Event Card</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="close"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="handleSubmit">
            <!-- Title -->
            <div class="mb-3">
              <div class="form-floating">
                <input type="text" class="form-control" id="floatingInputValue" placeholder="Event Title"
                  v-model="card.title">
                <label for="floatingInputValue">Event Title</label>
              </div>
            </div>
            <!-- Description -->
            <div class="mb-3">
              <div class="form-floating">
                <input type="text" class="form-control" id="floatingInputValue" placeholder="Event Text"
                  v-model="card.description">
                <label for="floatingInputValue">Call for action</label>
              </div>
            </div>
            <!-- Max effect per day -->
            <div class="mb-3">
              <div class="form-floating">
                <input type="number" class="form-control" id="floatingInputValue" placeholder="0"
                  v-model="card.maxEffectPerDay">
                <label for="floatingInputValue">Max points per day (0 for no limit)</label>
              </div>
            </div>
            <!-- Max effect per event -->
            <div class="mb-3">
              <div class="form-floating">
                <input type="number" class="form-control" id="floatingInputValue" placeholder="0"
                  v-model="card.maxEffectPerEvent">
                <label for="floatingInputValue">Max points per event (0 for no limit)</label>
              </div>
            </div>
            <!-- Good event -->
            <div class="mb-3 form-check d-flex align-items-center">
              <input type="checkbox" class="form-check-input me-2" id="goodEvent" v-model="card.isGoodEvent">
              <label class="form-check-label" for="goodEvent">Good Event</label>
            </div>
            <!-- Focus -->
            <div class="mb-3 form-check d-flex align-items-center">
              <input type="checkbox" class="form-check-input me-2" id="focus" v-model="card.onFocus">
              <label class="form-check-label" for="focus">On Focus</label>
            </div>
            <hr />
            <!-- Context -->
            <div class="mb-3">
              <label for="context" class="form-label">Context</label>
              <div v-for="(context, index) in card.context" :key="index">
                <div class="input-group mb-2" v-if="!context.isDeleted">
                  <input type="text" class="form-control" v-model="context.name" placeholder="Name" required>
                  <input type="number" class="form-control" v-model="context.points" placeholder="Value" required>
                  <button type="button" class="btn btn-danger" @click="removeContext(index)">Remove</button>
                </div>
              </div>
              <button type="button" class="btn btn-outline-success mt-1 w-100" @click="addContext">Add Context</button>
            </div>
            <!-- Buttons -->
            <hr />
            <button type="button" class="btn btn-outline-danger m-1" data-bs-dismiss="modal"
              @click="deleteCard()">Delete</button>
            <button type="button" class="btn btn-outline-primary m-1" data-bs-dismiss="modal"
              @click="handleSubmit()">Save changes</button>
            <button type="button" class="btn btn-outline-secondary m-1" data-bs-dismiss="modal"
              @click="close()">Close</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { IEventCard } from '@/store/interfaces'
import { ref, onMounted, watch, nextTick } from 'vue'
import { useStore } from 'vuex'

// Props
const props = defineProps<{
  cardId?: number | string | null
}>()

const emit = defineEmits(['close', 'save'])

// Refs
const launchButton = ref<HTMLDivElement | null>(null)
const store = useStore()
const mode = ref<'add' | 'edit'>('add')
const card = ref<IEventCard>({
  title: '',
  id: 0,
  description: '',
  context: [],
  onFocus: false,
  isDeleted: false,
  maxEffectPerDay: 0,
  maxEffectPerEvent: 0,
  isGoodEvent: false,
  position: 0
})

// Init
const triggerLaunch = () => {
  if (launchButton.value) {
    launchButton.value.click()
  }
}

const resetCard = () => {
  card.value = {
    title: '',
    id: 0,
    description: '',
    context: [],
    onFocus: false,
    isDeleted: false,
    maxEffectPerDay: 0,
    maxEffectPerEvent: 0,
    isGoodEvent: false,
    position: 0
  }
}

const loadCard = async (id: number | string) => {
  const loadedCard = await store.dispatch("getCard", id)
  if (loadedCard) {
    card.value = loadedCard
    mode.value = 'edit'
  }
}

watch(() => props.cardId, async (newId, oldId) => {
  if (newId !== oldId) {
    if (newId) {
      await loadCard(newId)
    } else {
      resetCard()
      mode.value = 'add'
    }
    await nextTick()
    triggerLaunch()
  }
}, { immediate: true })

// Methods
const close = () => {
  resetCard()
  emit('close')
}

const deleteCard = async () => {
  card.value.isDeleted = true
  await handleSubmit()
}
const handleSubmit = async () => {
  if (mode.value === 'edit') {
    await store.dispatch('updateCard', card.value)
  } else {
    await store.dispatch('addCard', {
      title: card.value.title || "",
      description: card.value.description || "",
      maxEffectPerDay: card.value.maxEffectPerDay || 0,
      maxEffectPerEvent: card.value.maxEffectPerEvent || 0,
      onFocus: card.value.onFocus || false,
      isGoodEvent: card.value.isGoodEvent || false,
      context: card.value.context || []
    })
  }
  emit('save')
  close()
}

const addContext = () => {
  if (!card.value.context) card.value.context = []
  card.value.context.push({ name: '', points: 0, isDeleted: false })
}

const removeContext = (index: number) => {
  card.value.context[index].isDeleted = true
}
</script>

<style scoped>
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-content {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
</style>