//store/pointbook.ts

import { ActionContext } from 'vuex';
import axios from 'axios';
import { IEventCard, IHistoryEntry, IPoints, ITask } from '@/store/interfaces'

interface State {
  points: IPoints | null,
  checkedIn: boolean | null,
  history: IHistoryEntry[] | null,
}

const state: State = {
  points: null,
  checkedIn: null,
  history: null,
}

const getters = {
  points: (state: State): IPoints | null => state.points,
  checkedIn: (state: State): boolean | null => state.checkedIn,
  history: (state: State): IHistoryEntry[] | null => state.history,
};
const actions = {
  async bookPoints({ dispatch }: ActionContext<State, any>, card: IEventCard) {
    try {
      const body = JSON.stringify(card);
      await axios.post(`/bookPoints`, body)
      await dispatch('getPoints');
      return
    } catch (error) {
      console.error(error)
      return []
    }
  },
  async deleteHistoryEntry({ dispatch }: ActionContext<State, any>, entry: IHistoryEntry) {
    try {
      await axios.delete(`/pointsBook/${entry.id}`) 
      await dispatch("getHistory")
    } catch (error) {
      console.error(error)
    }
  },
  async getPoints({ commit }: ActionContext<State, any>) {
    try {
      const req = await axios.get(`/pointsBook`)
      const points = req.data.pointBook[0] as IPoints

      commit('setPoints', points)
      return points
    } catch (error) {
      console.error(error)
      return []
    }
  },
  async getHistory({ commit }: ActionContext<State, any>) {
    try {
      const req = await axios.get(`/user/history`)
      const history = req.data.pointBook as IHistoryEntry[]

      commit('setHistory', history)
      return history
    } catch (error) {
      console.error(error)
      return []
    }
  },
  async getHistoryEntry(_: ActionContext<State, any>, entry: IHistoryEntry) {
    try {
      const req = await axios.get(`/pointsBook/${entry.id}`)
      const history = req.data.pointBook[0] as IHistoryEntry

      return history
    } catch (error) {
      console.error(error)
      return []
    }
  },
  async checkIn({ getters, commit, dispatch }: ActionContext<State, any>) {
    try {
      dispatch("getCheckInState")
      const checkedIn = getters.checkedIn
      const route = checkedIn ? 'checkOut' : 'checkIn'
      await axios.post(`/user/${route}`)
      commit("checkInOrOut", !checkedIn);

      // Update points
      await dispatch("getPoints")

      return await dispatch('getCheckInState');
    } catch (error) {
      console.error(error)
      return []
    }
  },
  async getCheckInState({ commit }: ActionContext<State, any>) {
    try {
      const req = await axios.get(`/user/isCheckedIn`)
      const checkedIn = req.data.user[0].checkedIn
      commit("checkInOrOut", checkedIn);
      return checkedIn
    } catch (error) {
      console.error(error)
      return []
    }
  },
};

const mutations = {
  setPoints(state: State, points: IPoints) {
    state.points = points;
  },
  checkInOrOut(state: State, _state: boolean) {
    state.checkedIn = _state
  },
  setHistory(state: State, history: IHistoryEntry[]) {
    state.history = history
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
